<template>
	<div class="o-wrap">
		<el-page-header @back="$router.go(-1)" content="发布企业要闻"/>
		<CompanyForm @eventForm="handleGetForm" class="purForm" />
	</div>
</template>

<script>
	import CompanyForm from '@/components/Company/Form'
	export default {
		methods: {
			handleGetForm(form) {
				this.$axios.post('/addnews', form).then(data => {
					this.$message.success('发布成功');
					this.$router.push('/company')
				})
			}
		},
		components: {
			CompanyForm
		}
	}
</script>